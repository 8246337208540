import Inputmask from 'inputmask'

/*
  When called, it will be look for in a element:

  Money:
    Ex: data-mask-money="€"

  Years:
    Ex: data-mask-years="years old"

  Percentage:
    Ex: data-mask-percentage
        ^ This needs to be expanded to accept how many decimals you want to display

  On a Rails tag you should use it this way:
    :data => {:mask_money => '£'}
    :data => {:mask_percentage => '%'}
    :data => {:mask_years => '<text to add>'}
*/
const maskTypes = function(elem) {
  return {
    money:{
      alias: 'numeric',
      prefix: elem.dataset.maskMoney,
      groupSeparator: ',',
      autoGroup: true,
      digits: (function(){
        let decimalPlaces = elem.dataset.maskMoneyDecimal;
        if (decimalPlaces == undefined) {
          decimalPlaces = 0
        }
        return decimalPlaces
      })(),
      rightAlign: false,
      placeholder: '',
      clearMaskOnLostFocus: false,
      removeMaskOnSubmit: true,
      showMaskOnHover: false,
      autoUnmask: true
    },
    years:{
      alias: 'numeric',
      suffix: ' ' + elem.dataset.maskYears,
      groupSeparator: ',',
      autoGroup: true,
      digits: 0,
      rightAlign: false,
      clearMaskOnLostFocus: false,
      removeMaskOnSubmit: true,
      showMaskOnHover: false,
      autoUnmask: true
    },
    percentage:{
      alias: 'numeric',
      suffix: '%',
      groupSeparator: ',',
      autoGroup: true,
      digits: 2,
      rightAlign: false,
      placeholder: '',
      removeMaskOnSubmit: true,
      showMaskOnHover: false,
      autoUnmask: true
    }
  }
}

const setActive = function(elem) {
  elem.setAttribute("data-mask-active", "")
}

const isAllowed = function(elem) {
  return !elem.hasAttribute("data-mask-active")
}

const createMask = function(type){
  const inputToMask = Array.from(document.querySelectorAll(`[data-mask-${type}]`));
  inputToMask.filter(isAllowed).forEach(function(elem) {
    Inputmask(maskTypes(elem)[type]).mask(elem);
    setActive(elem)
  })
}

export default function init() {
  createMask('money')
  createMask('years')
  createMask('percentage')
}

(function() {
  document.addEventListener('DOMContentLoaded', function () {
    init()
  })
 })();


