/* eslint-disable no-param-reassign */
import confirmCallback from '../utils/confirm_callback';
import deleteString from '../utils/type_delete_string';

/* *******************************************************
 ** Functions called when the Fact Find Section is saved **
 ******************************************************** */
const cleanInputError = (input) => {
  const formGroup = input.closest('fieldset') || input.parentElement;
  const alertToClean = formGroup.querySelector('[role="alert"]');

  if (input.type === 'radio') {
    input.parentElement.querySelector('label').classList.remove('b-xs-red-500', 'text-xs-red-500');
    const inputsToClean = formGroup.querySelectorAll('input.b-xs-red-500');
    // Clean siblings (like yes/no radio buttons)
    inputsToClean.forEach(cleanInputError);
  } else {
    input.classList.remove('b-xs-red-500');
  }

  formGroup.classList.remove('has-error');
  // Added the following block to deal with the removal of error messages 
  // in the dropdowns, upon selecting a value
  const formGroupParent = formGroup.closest('.has-error');
  if (formGroupParent) {
    formGroupParent.classList.remove('has-error');
  }
  if (alertToClean) {
    deleteString(alertToClean);
  }
};

// Function to change the submit button status (disabled vs enabled)
const toggleFactFindSubmitButton = (status) => {
  const submitButton = document.querySelector('.js-fact-find-save');

  if (status === 'disable' || !status) {
    submitButton.classList.add('is-disabled');
    submitButton.disabled = true;
  } else if (status || status === 'enable') {
    submitButton.classList.remove('is-disabled');
    submitButton.disabled = false;
  }
};

/*
 Whenever an element is changed in the window
 removes the validation messages and enables the submit button
 */
const bindChange = (event) => {
  const element = event.target;

  if (element && element.matches('.js-fact-find-form input, .js-fact-find-form select, .js-fact-find-form textarea')) {
    toggleFactFindSubmitButton('enable');
    cleanInputError(element);
  }
};

/*
  Tracks changes in the document
 */
document.addEventListener('change', bindChange);
document.addEventListener('input', bindChange);

let formSuccess = true; // is the form a success or not?

// function to validate a field
// adds the class .validation-error to a form-group if the field/input is not valid
// adds a `validation-message` with an error message
const validateField = (element, field, fieldName) => {
  const formGroup = element.closest('.form-group') === null ? element.parentElement.querySelector('.form-group') :
    element.closest('.form-group');
  // in the segmented control fields to display the error message correctly
  // the upper form-group element must be targeted
  const formControl = formGroup.closest('.form-control');
  const target = formControl || formGroup;
  target.classList.add('has-error');
  if (target.querySelector('.text-xs-red-500') === null) {
    let finalMessage = '';
    field[fieldName].forEach((message) => {
      finalMessage += `${message}`;
    });
    target.insertAdjacentHTML('afterend', `<span class='font-xs-12 font-xs-italic text-xs-red-500' id='${fieldName}-validation' role='alert'>${finalMessage}</span>`);
  }


  formSuccess = false;
};

/**
 * Adds validation messages to regular cards and card lists
 * @param cardId
 * @param cardObject
 */
const validateCard = (cardId, cardObject) => {
  Object.keys(cardObject).forEach((card) => {
    Object.keys(cardObject[card]).forEach((field) => {
      if (cardObject[card]) {
        const fieldElement = document.querySelector(`[data-cardid='${cardId}'] [name*='[${field}]']`);

        // will only apply the validation messages if the field is on screen
        if (fieldElement != null) {
          const messages = cardObject[card];
          // Validate if it's a single field
          validateField(fieldElement, messages, field);
        }
      }
    });
  });
};

/**
 * Adds validation messages to object tables
 * @param cardId
 * @param rows
 */
const validateObjectTable = (cardId, rows) => {
  Object.keys(rows).forEach((rowErrorIndex) => {
    Object.keys(rows[rowErrorIndex]).forEach((rowError) => {
      Object.keys(rows[rowErrorIndex][rowError]).forEach((field) => {
        // Get the row number for this error
        const errorIndex = rowError.substring(rowError.indexOf('_err') + 4);
        // Find the actual input by searching in the card for inputs with the same name
        // And get the nth input found
        const element = document.querySelectorAll(`[data-cardid='${cardId}'] [name$='[${field}]']`)[errorIndex];
        validateField(element, rows[rowErrorIndex][rowError], field);
      });
    });
  });
};

// Called on views/fact_find/update.js.erb
const showInputValidations = (formInfo) => {
  // Clean previous errors
  const errorsToClean = document.querySelectorAll('.text-xs-red-500');
  errorsToClean.forEach((element) => {
    element.remove();
  });

  if (formInfo !== null) {
    Object.keys(formInfo).forEach((cardId) => {
      const cardObject = formInfo[cardId];
      if (cardObject.length === undefined) {
        validateCard(cardId, cardObject);
      } else {
        // It's an object table (the errors are an array)
        validateObjectTable(cardId, cardObject);
      }
    });
  }

  // Show success message
  confirmCallback(`
      <span class="font-xs-14" >
        <i class="icon icon-${formSuccess ? 'check' : 'warning'} font-xs-17 p-xs-right-0-5"></i>
        All your changes have been saved${!formSuccess ? ', but you\'re still missing mandatory fields' : ''}.
      </span>
    `, {
    targetContainer: document.querySelector('#layout-notifications'),
    classToAdd: `bg-xs-${formSuccess ? 'green' : 'yellow'}-100`,
    dismiss: true,
  });

  const currentSection = document.querySelector('.vertical-menu-item.is-active');
  if (formSuccess) {
    currentSection.classList.add('is-complete');
  } else {
    currentSection.classList.remove('is-complete');
    // Focus on the first input with error
    const firstError = document.querySelector(".text-xs-red-500:not([type='hidden'])");
    firstError.closest('.form-section').querySelector('input').focus();
    // reset form success for next validation
    formSuccess = true;
  }

  toggleFactFindSubmitButton('disable');
};

export { showInputValidations, toggleFactFindSubmitButton };
