/*
  Classes used by these functions:

  > js-object-table

  > js-object-table-row
    - js-object-table-calculatable
    - js-object-table-section-total
    - js-is-calculated

  > js-object-table-section-totals
    - js-object-table-section-total
    - js-object-table-total

  > js-object-table-remove-line
  > js-object-table-add-line
*/

import { initDropdown } from '../plugins/dropdown';
import initMasks from './masks';

// Calculate and set the total value using each section total
const calculateObjectTableTotals = () => {
  let sectionTotals = 0;
  document.querySelectorAll('.js-object-table-section-totals .js-object-table-section-total').forEach((sectionTotal) => {
    sectionTotals += parseInt(sectionTotal.value, 10);
  });
  if (document.querySelector('.js-object-table-total') !== null) {
    document.querySelector('.js-object-table-total').value = sectionTotals;
  }
};

// Function to calculate the totals and sub-totals
const calculateObjectTableSectionTotals = (sectionTable) => {
  // Sums the annual values on each card
  let totalSum = 0;
  // For each calculatable input in the section, sum the values
  sectionTable.querySelectorAll('.js-object-table-calculatable:not(.js-is-calculated)').forEach((element) => {
    if (element.value !== '') {
      totalSum += parseFloat(element.value) * parseFloat(element.dataset.totalsMultiplier);
    }
  });

  // Update this section's totals everywhere
  document.querySelectorAll(`.js-object-table-section-total[data-section-name="${sectionTable.dataset.sectionName}"]`).forEach((element) => {
    element.value = totalSum; // eslint-disable-line no-param-reassign
  });

  // Update the table totals
  calculateObjectTableTotals();
};

// Delete table rows
const removeObjectTableLine = (event) => {
  event.preventDefault();

  const row = event.target.closest('.js-object-table-row');
  const table = row.closest('.js-object-table');
  if (row.parentNode) {
    row.parentNode.removeChild(row);
  }

  calculateObjectTableSectionTotals(table);
  if (window.toggleFactFindSubmitButton) {
    window.toggleFactFindSubmitButton('enable');
  }
};

/*
  Calculates values for other fields in the same row if necessary
  Only calculates values if the updated field has a totalsMultiplier data attribute
*/
const calculateObjectTableValuesInRow = (event) => {
  const updatedInput = event.target;

  if (!updatedInput.dataset.totalsMultiplier) {
    return;
  }
  const listRow = updatedInput.closest('.js-object-table-row');

  // For each calculatable input in the row, update the number and define it has having been calculated
  listRow.querySelectorAll(`.js-object-table-calculatable:not(#${updatedInput.id})`)
    .forEach((element) => {
      const otherInput = element;
      // eslint-disable-next-line max-len
      otherInput.value = (updatedInput.value * updatedInput.dataset.totalsMultiplier) / otherInput.dataset.totalsMultiplier;
      otherInput.classList.add('is-calculated', 'js-is-calculated');
    });

  updatedInput.classList.remove('is-calculated', 'js-is-calculated');
};

// Event listener to remove a table object line
const initDestroy = () => {
  document.querySelectorAll('.js-object-table-remove-line').forEach((item) => {
    item.addEventListener('click', removeObjectTableLine);
  });
};

const addObjectTableLine = (event) => {
  event.preventDefault();
  const clickedLink = event.currentTarget;
  const linkDiv = clickedLink.closest('.js-object-table-add-line');
  const table = clickedLink.closest('.js-object-table');
  const totalsRow = table.querySelector('.js-object-table-totals-row');
  let sectionName = linkDiv.dataset.sectionName.toLowerCase();

  if (sectionName !== 'milestones') {
    sectionName = 'expenditures';
  }

  let rowTemplate = document.querySelector(linkDiv.dataset.lineTemplate).innerHTML;
  let index = 0;
  let fieldElements = [];
  let fieldName = null;

  do {
    fieldName = `fact_find_form[profile_sections][${linkDiv.dataset.cardId}][${sectionName}][${index}]`;
    fieldElements = document.querySelectorAll(`[name^='${fieldName}']`);
    if (fieldElements.length) {
      index += 1;
    }
  } while (fieldElements.length);

  rowTemplate = rowTemplate.replace(/\[0\]/g, `[${index}]`);
  rowTemplate = rowTemplate.replace(/!section_name!/g, linkDiv.dataset.sectionName);

  totalsRow.insertAdjacentHTML('beforebegin', rowTemplate);
  const rowToBind = document.querySelectorAll(`[name^='${fieldName}']`)[0].closest('.row');
  rowToBind.querySelectorAll('.js-object-table .js-object-table-calculatable').forEach((item) => {
    item.addEventListener('input', (inputEvent) => {
      calculateObjectTableValuesInRow(inputEvent);
      calculateObjectTableSectionTotals(inputEvent.target.closest('.js-object-table'));
    });
  });

  const addRemoveLineBindElements = table.querySelectorAll('.js-object-table-remove-line');
  addRemoveLineBindElements.forEach((removeButton, i) => {
    if (index === i) {
      removeButton.addEventListener('click', removeObjectTableLine);
    }
  });

  window.initChoices();
  initMasks();
  initDropdown();
  initDestroy(); // reload to allow new rows to be removed
};

// Handler for the focus event
// If the field is automatically calculated remove the is-calculated class and
// add it to the sibling that isn't calculated
// If the field hasn't been calculated just removes the css class and adds it to all siblings
const focusObjectTableInput = (event) => {
  event.preventDefault();
  const focusedInput = event.target;

  const listRow = focusedInput.closest('.js-object-table-row');
  const siblingFields = listRow.querySelectorAll(`.js-object-table-calculatable:not(#${focusedInput.id})`);

  if (focusedInput.classList.contains('js-is-calculated')) {
    // Add focus to this field (remove the style class)
    focusedInput.classList.remove('is-calculated');
    // Add the class to the sibling that has it
    const calculatedSibling = [];
    siblingFields.forEach((element) => {
      if (!element.classList.contains('js-is-calculated')) {
        calculatedSibling.push(element);
      }
    });
    if (calculatedSibling.length === 1) {
      calculatedSibling[0].classList.add('is-calculated');
    }
  } else {
    focusedInput.classList.remove('is-calculated');
    siblingFields.forEach((element) => {
      element.classList.add('is-calculated');
    });
  }
};

// Handler for the outfocus event
// If the field is still automatically calculated add the is-calculated class and
// removes it from the input that has been manually inserted
// If the field hasn't been calculated, remove the css class
const outFocusObjectTableInput = (event) => {
  event.preventDefault();
  const outFocusedInput = event.target;

  if (outFocusedInput.classList.contains('js-is-calculated') && !outFocusedInput.classList.contains('is-calculated')) {
    outFocusedInput.classList.add('is-calculated');
    const listRow = outFocusedInput.closest('.js-object-table-row');
    const siblingFields = listRow.querySelectorAll(`.js-object-table-calculatable:not(#${outFocusedInput.id})`);
    const uncalculatedSibling = siblingFields.not('js-is-calculated');
    if (uncalculatedSibling.length === 1) {
      uncalculatedSibling.classList.remove('is-calculated');
    }
  } else {
    outFocusedInput.classList.remove('is-calculated');
  }
};

const initObjectTables = () => {
  document.querySelectorAll('.js-object-table .js-object-table-calculatable').forEach((item) => {
    item.addEventListener('input', (event) => {
      calculateObjectTableValuesInRow(event);
      calculateObjectTableSectionTotals(event.target.closest('.js-object-table'));
    });
  });

  document.querySelectorAll('.js-object-table-add-line').forEach((item) => {
    item.addEventListener('click', addObjectTableLine);
  });

  document.querySelectorAll('.js-object-table-calculatable').forEach((item) => {
    item.addEventListener('focusout', outFocusObjectTableInput);
    item.addEventListener('focus', focusObjectTableInput);
  });

  // On document load, existing rows can be deleted
  initDestroy();
};

/* eslint-disable import/prefer-default-export */
export { initObjectTables };
