import { createPopper } from '@popperjs/core';

const initDropdown = () => {

  const items = document.querySelectorAll(".dropdown");

  items.forEach((item) => {
    const label = item.querySelector(".dropdown__label");
    const wrapper = item.querySelector(".dropdown__wrapper");

    // match width of both elements
    // const labelWidth = label.getBoundingClientRect().width;
    // const wrapperWidth = label.getBoundingClientRect().width;
    // const targetWidth = `${Math.ceil(Math.max(labelWidth, wrapperWidth))}px`;
    // wrapper.style.minWidth = targetWidth;
    // label.style.minWidth = targetWidth;

    // use event delegation to avoid applying multiple listeners to click & label

    document.body.addEventListener("click", (e) => {
      const path = e.path ? e.path : e.composedPath();

      const clickedInLabel = path.includes(label);
      const clickedinWrapper = path.includes(wrapper);

      // do nothing if clicking inside the wrapper
      if (clickedinWrapper) return;

      // toggle opened when clicking in label
      if (clickedInLabel) {
        wrapper.classList.toggle("is-open")
        label.classList.toggle("is-open")
        return;
      }

      // close on any other situation
      if (wrapper.classList.contains("is-open")) {
        wrapper.classList.remove("is-open")
        label.classList.remove("is-open")
      }
    });

    createPopper(label, wrapper, {
      // https://popper.js.org/docs/v2/constructors/#options
      placement: "bottom-start",
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, 16] // distance from label
          }
        }
      ]
    });
  });
}

export { initDropdown };