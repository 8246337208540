/*
  # USAGE

  ## This should be added on the trigger element:
  data-dynamic-target="<target_name>"

  ## This should be added on the target element/wrapper:
  data-dynamic='{"<target_name>": {"show": true|false, "content": "<text_to_add>"}, "<other_target_name>": {"show": true|false, "content": "<other_text_to_add>"}}'
  ## Notes:
  - If you're using a select on the 'data-dynamic' you should use the value for <target_name> instead;
  - You can add as much as 'data-dynamic' as you want/need to diferent elements as needed;
  - It's possible to add multiple entries to the 'data-dynamic' attribute so that it has multiple changes from diferent sources;

*/
const enableEvent = new Event('enable');
const disableEvent = new Event('disable');
// Object with {id: {domObject: ..., action: object }}
const targets = {};

const toggleElements = (elem, bool) => {
  if (bool) {
    elem.classList.remove('hidden-xs');
    elem.setAttribute('aria-hidden', false);
    elem.querySelectorAll('input, select').forEach((field) => {
      field.removeAttribute('disabled');
      field.dispatchEvent(enableEvent);
    });
  } else {
    elem.classList.add('hidden-xs');
    elem.setAttribute('aria-hidden', true);
    elem.querySelectorAll('input, select').forEach((field) => {
      field.setAttribute('disabled', true);
      field.dispatchEvent(disableEvent);
    });
  }
};

const dynamicToggler = (trigger) => {
  const togglerID = trigger.dataset.dynamicTarget;

  // In the event the toggler is a radio/checkbox inside a group
  // const triggerGroup = document.querySelectorAll(`[name="${trigger.getAttribute('name')}"]`);

  const { domObject } = targets[togglerID];
  const { action } = targets[togglerID];

  Object.keys(action).forEach((key) => {
    const bool = trigger.tagName === 'SELECT' ? action[key] === trigger.value : action[key];

    if (key === 'show') {
      toggleElements(domObject, bool);
    } else if (key === 'content') {
      domObject.innerText = action[key][trigger.value];
    }
  });
};

const cacheTargetElements = (domParent) => {
  const targetElements = domParent.querySelectorAll('[data-dynamic]');

  targetElements.forEach((elem) => {
    const data = JSON.parse(elem.dataset.dynamic);
    Object.keys(data).forEach((key) => {
      targets[key] = {
        domObject: elem,
        action: data[key],
      };
    });
  });
};


const initDynamicToggler = (parent) => {
  const domParent = parent || document;
  cacheTargetElements(domParent);

  const triggers = domParent.querySelectorAll('[data-dynamic-target]');

  triggers.forEach((trigger) => {
    trigger.addEventListener('change', (event) => {
      dynamicToggler(event.currentTarget);
    });
  });
};


export { initDynamicToggler };
