import Rails from '@rails/ujs';
import '../plugins/choices';
import { initDynamicToggler } from '../utils/dynamic_toggler';
import { initObjectTables } from '../utils/object_tables';
import { showInputValidations, toggleFactFindSubmitButton } from './fact_find_validation';
import initMasks from '../utils/masks';
import { initDropdown } from '../plugins/dropdown';

/*
  Manage card lists
  The js-toggle-inner-cards class must be added to the wrapper (.js-form-group)
  Each input should then have the following data attributes:
  > path - the relative path to the endpoint
  > method - the request method

  When an input is selected a request to `path` will be triggered
*/

// When a card list question is toggled, triggers a call to the defined URLs
const innerCardToggler = (event) => {
  const selectedInput = event.target;

  var path = selectedInput.dataset.path;
  var method = selectedInput.dataset.method;

  Rails.ajax({
    url: path,
    type: method,
    dataType: 'script',
    accept: 'script',
  });
};

/*
  Toggles the existence of a card from the value of an input elsewhere
  The js-toggle-card class must be added to the control wrapper (.form-group)
  The input should then have the following data attributes:
  > card-type the card type to be toggled
  > add-card-path the path to create the new card
  > show-conditions an array of values that will show the card
*/
const cardToggler = (event) => {
  const changedInput = event.target;
  const valuesToLook = changedInput.dataset.showConditions;
  // Determine the area we're going to act on
  const cardTarget = document.querySelector('[data-cardstarget="' + changedInput.dataset.cardType + '"]');

  if (valuesToLook.includes(changedInput.value)) {
    cardTarget.classList.remove('hidden-xs');
    // Check if there is aa hidden card
    if (cardTarget.querySelectorAll('.js-card').length == 0) {
      // Card doesn't exist
      Rails.ajax({
        url: changedInput.dataset.addCardPath,
        type: 'POST',
        dataType: 'script',
        accept: 'script',
      });
    }
  } else {
    cardTarget.classList.add('hidden-xs');
    cardTarget.querySelectorAll('.js-card').forEach((element) => {
      element.remove();
    });
  }
};

/* *******************************************************
** Functions called when the Fact Find Section is saved **
******************************************************** */

/*
  Overrides the submit input to save changes.

  Before submitting the changes to the server,
  this will handle any inputs that had to be locally calculated and need
  extra care before being submitted
*/
const bottomNavSaveChanges = (event) => {
  const eventTarget = event.target;

  // Move the automatically calculated values to a data attribute (We only want user submitted data)
  document.querySelectorAll('.js-is-calculated').forEach(function (elem, _index) {
    elem.disabled = true;
  });

  Rails.fire(eventTarget.closest('form'), 'submit');

  // Move the automatically calculated values back to the value attribute
  document.querySelectorAll('.js-is-calculated').forEach(function (elem, _index) {
    elem.disabled = false;
  });
};

/*
  Whenever an element is changed in the window
  removes the validation messages and enables the submit button
*/

// Check if there were any changes
let formChanged = false;

const bindChange = (event) => {
  const element = event.target;

  if (element === null || !element.matches('.js-fact-find-form input, .js-fact-find-form select, .js-fact-find-form textarea')) {
   return
  }

  formChanged = true;
  toggleFactFindSubmitButton('enable');
  const formGroup = event.target.closest('.form-section');

  // remove validation-error class
  const errorSpan = formGroup?.querySelector('.text-xs-red-500');
  // remove validation message
  if (errorSpan) {
    errorSpan.remove();
  }
  // else {
  //   if (formGroup.querySelectorAll('.radio').length !== 0 && formGroup.closest('.form-control') !== null) {
  //     // Radio controls have a slightly more complex structure because there are several inputs
  //     const formControlMessage = formGroup.closest('.form-control').querySelector('.validation-message')
  //     if (formControlMessage !== null) {
  //       formControlMessage.remove();
  //     }
  //   }
  // }
};

/**
 * Adds the necessary tools to trigger behaviours in some areas after a field's value is changed
 * elsewhere.
 * The responsibility to determine what changes are supposed to happen falls on the affected area.
 * Nomenclature used is the same as in the Fact-find.
 *
 * The input working as a trigger (the affector) must have the .js-consequence-affector class and
 * a path set as an attribute (data-path).
 * In the Fact-find, the path corresponds to the field's absolute path.
 *
 * The affected area needs to define 2 attributes:
 * - data-affected-by, indicating the path of the field affecting the area
 * - data-consequences, a multidimensional map, indexed by the effect name, with another map
 * inside, indexed by the value which will trigger the new status (defined as the map value)
 * If no value is defined, the function will revert to the behaviour specified in the !default!
 * index. If that isn't defined, nothing will happen.
 *
 * Following effects are implemented:
 * - visibility, toggles the visibility of the affected area. Possible statuses: 'show', 'hide'.
 * - content, replaces the content of the affected area.
 */
const triggerConsequence = (event) => {
  const clickedElement = event.target;
  const affector = clickedElement.closest('.js-consequence-affector');
  const hiddenInput = affector.querySelector('input[type=hidden]');
  if (hiddenInput !== null) {
    hiddenInput.remove();
  }
  const affectorPath = affector.dataset.path;

  const enableEvent = new Event('enable');
  const disableEvent = new Event('disable');
  // Determine which field was changed, triggering the event
  let affectorField = affector.querySelector('input, select');
  // If the field is a radio array, we must select the checked element
  if (affectorField.type === 'radio') {
    affectorField = affector.querySelector('input:checked');
  }
  var affectorValue = affectorField.value;
  // Determine where we're going to look for affected elements.
  // If there is an js-inner-card close by, use it. If not, use the closest .js-card
  let consequenceArea = null;
  if (clickedElement.closest('.js-inner-card')) {
    consequenceArea = clickedElement.closest('.js-inner-card');
  } else {
    consequenceArea = clickedElement.closest('.js-card');
  }
  // Search for elements that are affected by the change
  const affectedElements = consequenceArea.querySelectorAll(`[data-affected-by*="!${affectorPath}!"]`);
  affectedElements.forEach((affectedElement) => {
    // Each element can be affected in several ways, loop them
    const consequences = JSON.parse(affectedElement.dataset.consequences)[affectorPath];
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const key in consequences) {
      const options = consequences[key];
      if (key === 'visibility') {
        // Toggle visibility of affectedElement according to the affector's value
        let actionToTake = options[affectorValue];
        // If an option isn't set, check if there is a default behaviour (using the !default! index)
        if (actionToTake === undefined) {
          actionToTake = options['!default!'];
        }
        if (actionToTake === 'show') {
          affectedElement.classList.remove('hidden-xs');
          affectedElement.setAttribute('aria-hidden', false);
          affectedElement.querySelectorAll('input, select').forEach((field) => {
            field.removeAttribute('disabled');
            field.dispatchEvent(enableEvent);
          });
        } else if (actionToTake === 'hide') {
          hide_children(affectedElement);
          affectedElement.classList.add('hidden-xs');
          affectedElement.setAttribute('aria-hidden', true);
          affectedElement.querySelectorAll('input, select').forEach((field) => {
            field.setAttribute('disabled', true);
            field.dispatchEvent(disableEvent);
          });
        }
      } else if (key === 'content') {
        // Change content of div according to the affector's value
        if (options[affectorValue] !== undefined) {
          affectedElement.innerHTML = options[affectorValue];
        }
      }
    }
  });
};

function hide_children(affectedElement){
  // Get div with js-consequence-affector from children
  const affector = affectedElement.querySelector('.js-consequence-affector');
  if(!affector)
    return;

  // Get the checked input that are children of a hiding field
  let affectorField = affector.querySelector('input:checked');
  // If the field has a radio button checked, remove the check
  if (affectorField) {
    affectorField.checked = false;
  }
  // Determine where we're going to look for affected elements.
  // If there is an js-inner-card close by, use it. If not, use the closest .js-card
  let consequenceArea = null;
  if (affectedElement.closest('.js-inner-card')) {
    consequenceArea = affectedElement.closest('.js-inner-card');
  } else {
    consequenceArea = affectedElement.closest('.js-card');
  }

  // Get path from affector
  const affectorPath = affector.dataset.path;
  // Get all fields affected by the affector
  const affectedElements = consequenceArea.querySelectorAll(`[data-affected-by*="!${affectorPath}!"]`);
  // Hide all children and disable their inputs
  affectedElements.forEach((affectedElement) => {
    affectedElement.classList.add('hidden-xs');
    // Disable elements inside
    affectedElement.querySelectorAll('input,select').forEach((toDisable) => {
      toDisable.disabled = true;
    });
    hide_children(affectedElement);
  });
}

/*
  Tracks changes in the document
*/
document.addEventListener('change', bindChange);
document.addEventListener('input', bindChange);

// Init Card togglers
const initCardListToggling = () => {
  const cardListToggler = document.querySelectorAll('.js-toggle-inner-cards input');
  cardListToggler.forEach((toggler) => {
    toggler.addEventListener('change', innerCardToggler);
  });
};

const initCardToggling = () => {
  const cardTogglers = document.querySelectorAll('.js-toggle-card');
  cardTogglers.forEach((toggler) => {
    const inputToggler = toggler.querySelector('input')
    const selectToggler = toggler.querySelector('select')
    if (inputToggler) {
      inputToggler.addEventListener('change', cardToggler);
    }
    if (selectToggler) {
      selectToggler.addEventListener('change', cardToggler);
    }
  });
};

/*
  Handles the scrolling behaviour on the toggling between the
  "write comment" button and the comment input
*/

const commentScroll = (action, buttonHeight) => {
  const layout = document.querySelector('#layout-page');
    if(action === "down"){
    layout.scrollTo({
      top:layout.scrollHeight,
      behavior: "smooth"
    });
  }
  if(action === "up"){
    const commentInput = document.querySelector('.js-comment-input');
    layout.scrollTo({
      top: layout.scrollTop - commentInput.scrollHeight + buttonHeight,
      behavior: "smooth"
    });
  }
}

// Handles the toggling between the "write comment" button and the comment input

const commentInputToggling = () => {
  // If there isn't a comment button, it means that the user doesn't have permission to comment
  // or that the fact-find is locked
  // We can, therefore, cancel the execution of this function
  if (document.querySelector('.js-write-comment') == null) {
    return;
  }

  // If is mobile move the modal to be a son of body element
  const isMobile = window.innerWidth < 512;
  if(isMobile) {
    const commentModal = document.getElementById('comment-modal');
    if(commentModal) {
      document.body.insertAdjacentElement('beforeend', commentModal);
    }
    // adding EventListener to the modal "close" button
    const closeModal = document.querySelector('.modal-close');
    if(closeModal) {
      closeModal.addEventListener('click', () => {
        commentModal.classList.remove('is-open');
        textareaInput.value = '';
      });
    }
  };

  const commentModal = document.getElementById('comment-modal');
  const commentBtn = document.querySelector('.js-write-comment').parentElement;
  const commentInput = document.querySelector('.js-comment-input');
  // if is Mobile target the modal elements
  const textareaInput = document.getElementById(isMobile? 'js-modal-comment-content' : 'js-comment-content');
  const cancelComment = document.querySelector(isMobile? '.js-modal-cancel-comment' : '.js-cancel-comment');
  const submitComment = document.querySelector(isMobile? '.js-modal-submit-comment' :'.js-submit-comment');
  const submitCommentBtn = document.querySelector(isMobile? '.js-modal-submit-comment-btn' : '.js-submit-comment-btn');

  const buttonHeight = commentBtn.scrollHeight;

  // Handle onClick of 'Write Comment' button
  commentBtn.addEventListener('click', event => {
    event.preventDefault();
    if(isMobile){
      commentModal.classList.add('is-open');
    } else {
      commentInput.classList.remove('hidden-xs');
      commentBtn.classList.add('hidden-xs');
      commentScroll('down');
    }
    textareaInput.focus();
  });

  // Handle onClick of 'cancel' button (close input without submiting a comment)
  cancelComment.addEventListener('click', () => {
    if (isMobile) {
      commentModal.classList.remove('is-open');
    } else {
      commentScroll('up', buttonHeight);
      setTimeout(() => {
        commentBtn.classList.remove('hidden-xs');
        commentInput.classList.add('hidden-xs');

      }, 130);
    }
    // disable submit comment for next comment
    submitCommentBtn.disabled = true;
    submitCommentBtn.classList.add('is-disabled');
    //reset textarea value
    textareaInput.value = '';

  });

  // Handle submit comment button is enabled or disabled
  textareaInput.addEventListener('input', () => {
    if(textareaInput.value !== ''){
      submitCommentBtn.disabled = false;
      submitCommentBtn.classList.remove('is-disabled');
    } else {
      submitCommentBtn.disabled = true;
      submitCommentBtn.classList.add('is-disabled');
    }
  });

  // Handle submiting a new comment
  submitCommentBtn.addEventListener('click', event => {
    event.preventDefault();
    if(isMobile) {
      commentModal.classList.remove('is-open');
    } else {
      commentBtn.classList.remove('hidden-xs');
      commentInput.classList.add('hidden-xs');
    }

    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    fetch(submitComment.dataset.submitCommentPath, {
      method: 'POST',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken,
      },
      body: JSON.stringify({ content: textareaInput.value })
    })
      .then(response => response.json())
      .then((data) => {
          // get new comment template
          let commentTemplate = document.getElementById('new-comment-template').innerHTML;
          // update values in template
          commentTemplate = commentTemplate.replace(/!comment_author!/, 'You');
          commentTemplate = commentTemplate.replace(/!comment_id!/g, data.id);
          commentTemplate = commentTemplate.replace(/!comment_created_at!/, 'less than a minute ago');
          commentTemplate = commentTemplate.replace(/!comment_content!/, data.content);
          commentTemplate = commentTemplate.replace(/!source_id!/, data.source);
          commentTemplate = commentTemplate.replace(/!source_type!/, 'FactFind::Section');
          // add new comment to the DOM
          let commentHistorySection = document.querySelector('.js-comments-history');
          commentHistorySection.insertAdjacentHTML('beforeend', commentTemplate);
          // clear textare input value
          textareaInput.value = '';
          // scroll down to display the comment that was just added
          commentScroll('down');
          // disable submit comment for next comment
          submitCommentBtn.disabled = true;
          submitCommentBtn.classList.add('is-disabled');

        // Hide existing delete links
        let deleteLinks = commentHistorySection.querySelectorAll('.js-delete-comment-link');
        deleteLinks.forEach((deleteLink) => {
          deleteLink.classList.add('hidden-xs');
        });
        // Show last delete link
        if (deleteLinks.length > 0 && data.can_destroy) {
          deleteLinks[deleteLinks.length - 1].classList.remove('hidden-xs');
        }
      });
  });
}

/*
  Load asynchronously new FF section
  Method called on views/fact_find/show.js.erb
*/
const initConsequences = (element) => {
  const elementToSearch = element || document;
  const cardConsequences = elementToSearch.querySelectorAll('.js-consequence-affector input, .js-consequence-affector select');
  cardConsequences.forEach((card) => {
    card.addEventListener('change', triggerConsequence);
  });
  initMasks();
}

const showSection = (currentSection, sectionHTML) => {

  document.querySelector('.js-fact-find').outerHTML = sectionHTML;
  const currentURL = window.location.origin;
  window.history.pushState({}, '', `${currentURL}/fact_find/${currentSection}`);

  const factFindSection = document.querySelector('.js-fact-find');

  window.initChoices();
  initDynamicToggler(factFindSection);
  initCardListToggling();
  initCardToggling();
  commentInputToggling();
  initObjectTables();
  initConsequences();
  initMasks();
  initDropdown();

  const saveFactFindButton = document.querySelector('.js-fact-find-save');
  if (saveFactFindButton !== null) {
    saveFactFindButton.addEventListener('click', bottomNavSaveChanges);
  }
};

const initFactFind = () => {
  const factFindPage = document.getElementById('layout-fact-find');

  if (factFindPage !== null) {

    initCardListToggling();
    initCardToggling();
    commentInputToggling();
    initObjectTables();
    initConsequences();
    initDynamicToggler();

    window.showSection = showSection;
    window.showInputValidations = showInputValidations;
    window.toggleFactFindSubmitButton = toggleFactFindSubmitButton;
    window.initConsequences = initConsequences;
    window.initChoices();

    const saveFactFindButton = document.querySelector('.js-fact-find-save');
    if (saveFactFindButton !== null) {
      saveFactFindButton.addEventListener('click', bottomNavSaveChanges);
    }
  }
};

const saveFactFind = (section) => {
  if (formChanged) {
    Rails.ajax({
      url: `/fact_find/${section}/save`,
      type: 'GET',
      dataType: 'script',
      accept: 'script',
    });
    formChanged = false;
  } else {
    Rails.ajax({
      url: `/fact_find/${section}`,
      type: 'GET',
      dataType: 'script',
      accept: 'script',
    });
  }
}

const changeFormStatus= () => {
  formChanged = false
}

export { initFactFind, saveFactFind, changeFormStatus };


// fact_find_save_modal_path(next_section: ff_section.fact_find_section_template.url_name)

