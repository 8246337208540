/* eslint-disable no-param-reassign */
let deleteStringInterval;

const Delete = (element) => {
  let string = element.innerText;
  string = string.substring(0, string.length - 1);
  element.innerText = string;

  if (string === '') {
    element.remove();
    clearInterval(deleteStringInterval);
  }
};

const deleteString = (element, interval) => {
  const timing = interval || (75 / element.innerText.length);

  deleteStringInterval = setInterval(Delete, timing, element);
};

export default deleteString;
