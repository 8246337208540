import Choices from 'choices.js';

const bindSelect = (select) => {
  const isDisabled = select.disabled;

  // Choices doesn't behave well with disabled attriutes
  // We need to "enable" the select and then disable Choices
  if (isDisabled) select.removeAttribute('disabled');

  // Add placeholder if there's no selected value already
  if(!select.value.length) {
    const placeholder = document.createElement('option');
    placeholder.setAttribute('value', '')
    placeholder.setAttribute('selected', '')
    placeholder.setAttribute('disabled', '')
    placeholder.setAttribute('placeholder', '')
    placeholder.innerHTML = select.dataset.placeholder;
    select.appendChild(placeholder)
  }
  

  const choices = new Choices(select, {
    paste: false,
    searchEnabled: select.children.length >= 7,
    shouldSort: false,
    classNames: {
      containerOuter: `choices ${select.dataset.choicesBorder === 'false' ? 'type-borderless' : ''}`,
    },
  });

  // Disable choices if select was disabled
  if (isDisabled) choices.disable();

  // Events to control the choices status without making Choices available outside of this scope
  select.addEventListener('enable', () => {
    choices.enable();
    choices.unhighlightAll()
  });

  select.addEventListener('disable', () => {
    choices.disable();
  });
};

const initChoices = (selectsList) => {
  const selects = selectsList || document.querySelectorAll('select');
  selects.forEach(bindSelect);
};

export { initChoices }
